<template>
  <div class="Team Liste part">
    <div class="constrain">
      <h2 class="title" v-html="payload.titel" />
      <div class="persons">
        <popup
          v-for="person in payload.team"
          :id="`person-${person.id}`"
          :key="person.id"
          v-model:open="personOpenStates[person.id]"
        >
          <template #origin="{ open }">
            <div class="member" @click="open">
              <div class="image">
                <Image :src="person.bild" />
              </div>
              <person-short lines line-size="2px" :person="person" />
            </div>
          </template>

          <template #default="{ close, isOpen }">
            <div class="popup-content" :class="{ open: isOpen }">
              <div class="close">
                <close-btn @click="close" />
              </div>
              <Image :src="person.bild" />
              <div class="text">
                <span class="description" v-html="person.beschreibung" />
                <div class="social">
                  <Go class="flex items-center" v-if="person.telefon" :to="`tel:${person.telefon}`">
                    <phone class="mr-4"/> {{person.telefon}}
                  </Go>
                  <Go v-if="person.mail" :to="`mailto:${person.mail}`">
                    <mail />
                  </Go>
                  <Go v-if="person.linkedin" :to="person.linkedin">
                    <linkedin />
                  </Go>
                  <Go v-if="person.xing" :to="person.xing">
                    <xing />
                  </Go>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import Mail from '@/assets/images/mail.svg?inline';
import Phone from '@/assets/images/phone.svg?inline';
import Linkedin from '@/assets/images/linkedin.svg?inline';
import Xing from '@/assets/images/xing.svg?inline';
import CloseBtn from '@/assets/images/Close_Button.svg?inline';

export default {
  components: {
    CloseBtn, Mail, Phone, Linkedin, Xing,
  },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const openPersonId = parseInt(window.location.hash.match(/#person-(\w+)/)?.[1], 10);
    const personOpenStates = reactive(
      Object.fromEntries(
        props.payload.team.map((person) => [person.id, openPersonId === person.id]),
      ),
    );

    return {
      personOpenStates,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

.title {
  margin-bottom: px(100);
}

.persons {
  display: grid;
  grid-gap: px(40);
  grid-template-columns: repeat(auto-fill, minmax(min(100%, #{px(400)}), 1fr));

  :deep(.line-box) {
    padding: 0.7rem 0;
  }
}

.member {
  display: flex;
  flex-flow: column;
  cursor: pointer;

  --line-color: currentColor;

  &:hover {
    --line-color: var(--color-area) !important;
  }

  .image {
    @apply rounded-full;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    margin: 0 auto 2rem auto;
    @include responsive('width', 80%, 80%);
    filter: grayscale(1);
    transition: all 200ms ease;

    &:hover {
      filter: grayscale(0);
    }

    :deep(img) {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }
}

.popup-content {
  position: relative;
  padding: 1rem;
  width: 100%;
  overflow-y: auto;
  display: grid;
  grid-gap: 2rem;
  background: white;
  border: 2px solid rgb(90, 90, 90);
  opacity: 0;
  max-height: 100%;

  @include responsive('grid-template-columns', 1fr, auto 100fr);
  @include responsive('justify-items', center, start);
  @include responsive('grid-template-rows', minmax(#{px(300)}, 1fr) 3fr, 1fr);

  &.open {
    opacity: 1;
  }

  :deep(img) {
    height: 100%;
    max-height: 641px;
    width: auto;
    aspect-ratio: 3 / 4;
    object-fit: cover;
    @include responsive('object-position', top, center);
    @include responsive('aspect-ratio', math.div(1, 1), math.div(3, 4));
    @include responsive('border-radius', 9999px, 0);
  }

  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;

    :deep(svg) {
      path {
        stroke: black;
      }
    }
  }
}

.description {
  display: block;

  :deep(h3) {
    margin-bottom: .5rem;
    margin-top: 1.5rem;
    @apply font-sans;
  }

  :deep(h2){
    @apply font-title;
    strong {
      font-weight: 300 !important;
    }
  }

  :deep(p) {
    margin-bottom: 1rem;
  }
}

.social {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin-top: 1rem;
  width: fit-content;

  svg {
    fill: var(--color-area);
    width: 2rem;
    height: 2rem;
  }
}
</style>
